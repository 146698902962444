import { initialMessageForInterviewQuestionsType, interviewQuestionsMessage, titleForInterviewQuestionsType } from "../../chat/utils/InterviewQuestionsHelper";
import InterviewQuestionsType from "../../shared/enums/InterviewQuestionsType";
import { newInterviewQuestionsChat } from "../../shared/models/Chat";
import Job from "../../shared/models/Job";
import Message, { MessageType } from "../../shared/models/Message";
import { setActiveChat } from "../reducers/appReducer";
import { AppDispatch, RootState } from "../store";
import { sendMessageAction } from "./ChatActions";

export const StartInterviewQuestionsFlowAction = () => {
    return async (dispatch: AppDispatch) => {
        try {
            const message: Message = {
                content: `What kind of interview questions would you like?`,
                dt: (new Date()).toISOString(),
                type: MessageType.received,
            }
            const newChat = newInterviewQuestionsChat([message], `Interview Questions`);
            dispatch(setActiveChat(newChat));
        } catch (error) {
            console.error('Error starting intrview questions chat:', error);
        }
    };
}

export const SetInterviewQuestionsTypeAction = (type: InterviewQuestionsType) => {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        try {
            const chat = getState().appState.activeChat;

            if (!chat) return;
            if (type == InterviewQuestionsType.resume) {
                dispatch(StartInterviewQuestionsAction(type));
                return;
            }

            // Recreate the chat with the new cover letter type
            const selectedMessage: Message = {
                content: initialMessageForInterviewQuestionsType(type),
                dt: (new Date()).toISOString(),
                type: MessageType.sent
            }
            const message: Message = {
                content: '',
                dt: (new Date()).toISOString(),
                type: MessageType.received,
            }

            const newChat = newInterviewQuestionsChat([selectedMessage, message], titleForInterviewQuestionsType(type), type);
            dispatch(setActiveChat({ ...newChat, sessionTitle: titleForInterviewQuestionsType(type) }));
        } catch (error) {
            console.error('Error setting interview qs type:', error);
        }
    };
}

export const StartInterviewQuestionsAction = (type: InterviewQuestionsType, job?: Job, role?: string) => {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        try {
            const state = getState();
            dispatch(sendMessageAction(interviewQuestionsMessage(type, state, job, role), true));
        } catch (error) {
            console.error('Error starting interview qs chat:', error);
        }
    };
}